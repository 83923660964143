<template>
  <b-modal
    id="modal-add-edit-incidente-otro"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    dialog-class="modal-dialog-scrollable"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="fa fa-bell"></i> Edición de Incidente</span
        >
      </div>
    </template>
    <b-form id="frm-add-edit-incidente-otro">
      <h5>{{ cabeceraIncidenteOtro.nombreLocalVotacion }}</h5>
      <h5>
        <small>{{ cabeceraIncidenteOtro.direccionLocalVotacion }}</small>
      </h5>
      <h5>
        <small class="text-muted"
          >{{ cabeceraIncidenteOtro.nombreRegion }} /
          {{ cabeceraIncidenteOtro.nonbreSubRegion }}</small
        >
      </h5>
      <hr />
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha y hora</label><br />
          <input
            type="datetime-local"
            id="input-12"
            class="form-control"
            v-model="incidenteOtro.horaEvento"
            placeholder=""
          />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Marque el caso que identifica:</label>
        </div>
        <div class="col-md-12">
          <table class="table mb-0">
            <tbody>
              <tr v-if="!casos">
                <td><i>Cargando casos...</i></td>
              </tr>
              <tr v-else v-for="caso in casos" :key="caso.parametroId">
                <td class="px-0">
                  <label
                    class="radio radio-danger radio-lg"
                    v-if="caso.codigo != 'IN_10'"
                  >
                    <input
                      type="radio"
                      v-model="incidenteOtro.tipoIncidenteParamId"
                      :value="caso.parametroId"
                    />
                    <span class="mr-2"></span>{{ caso.descripcion }}</label
                  >
                  <label
                    class="radio radio-danger radio-lg"
                    v-if="caso.codigo == 'IN_10'"
                    v-b-tooltip.hover.v-dark
                    title="Recuerde describir el incidente"
                  >
                    <input
                      type="radio"
                      v-model="incidenteOtro.tipoIncidenteParamId"
                      :value="caso.parametroId"
                    />
                    <span class="mr-2"></span>{{ caso.descripcion }}</label
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Indique el Nro. Mesa</label>
          <b-form-input
            id="input-9"
            v-model="incidenteOtro.numeroMesa"
            placeholder=""
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"
            >Indique si intervino alguna autoridad:</label
          >
          <b-form-input
            id="input-10"
            v-model="incidenteOtro.autoridadIntervencion"
            placeholder=""
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Describa el incidente:</label>
          <textarea
            class="form-control"
            ref="contentTextArea"
            id="contentTextArea"
            v-model="incidenteOtro.descripcion"
            placeholder=""
          ></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox
            id="checkbox-1"
            size="lg"
            v-model="incidenteOtro.esEliminado"
          >
          </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        @click.prevent="saveIncidenteOtro()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-incidente-otro",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    incidenteOtroId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    cabeceraIncidenteOtro: {},
    incidenteOtro: {
      localVotacionFichaIncidenteId: null,
      localVotacionFichaId: null,
      horaEvento: null,
      tipoIncidenteParamId: null,
      numeroMesa: "",
      autoridadIntervencion: "",
      descripcion: "",
      esEliminado: false,
    },
    casos: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    fetchIncidenteOtro: function(incidenteOtroId) {
      if (incidenteOtroId) {
        this.$http
          .get(this.apiRoute("Incident", "GetLocalVotacionIncidenteOtroById"), {
            params: {
              LocalVotacionFichaIncidenteId: incidenteOtroId,
            },
          })
          .then(function(response) {
            this.cabeceraIncidenteOtro = response.body.data.cabecera;
            this.incidenteOtro = response.body.data.incidente;
          });
      }
    },
    saveIncidenteOtro: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-incidente-otro")) {
        return this.sweetAlert(
          "Hay campos con valores incorrectos. Revise los datos ingresados."
        );
      }

      var model = {
        ...this.incidenteOtro,
      };

      this.estaGuardando = true;
      await this.$http
        .post(
          this.apiRoute("Incident", "AddEditLocalVotacionIncidenteOtros"),
          model
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
          }
        }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
    fetchTipoIncidentes: function() {
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: "TIPO_INCIDENTE",
          },
        })
        .then(function(response) {
          this.casos = response.body.data;
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
    "incidenteOtro.TipoIncidenteParamId": function() {
      this.$nextTick(() => {
        this.$refs.contentTextArea.focus();
      });
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.incidenteOtro.localVotacionFichaIncidenteId = this.incidenteOtroId;
    this.initParsley();
    this.fetchTipoIncidentes();
    this.fetchIncidenteOtro(this.incidenteOtro.localVotacionFichaIncidenteId);
  },
  destoyed: function() {},
};
</script>
