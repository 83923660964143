<template>
  <div>
    <!--Modal IncidenteOtros-->
    <div v-if="edit.mostrarModal">
      <mdl-incidente-otros v-model="edit.mostrarModal" :incidente-otro="edit.incidente" @onSave="handleMdlIncidenteOtrosSaved"></mdl-incidente-otros>
    </div>

    <!--Modal Edición IncidenteOtros-->
    <div v-if="editIncidente.mostrarModal">
      <mdl-add-edit-incidente-otro v-model="editIncidente.mostrarModal" :incidente-otro-id="editIncidente.incidenteOtroId" @onSave="handleMdlIncidenteOtrosSaved"></mdl-add-edit-incidente-otro>
    </div>

    <div class="alert alert-custom alert-light-info fade show" role="alert">
      <div class="alert-icon"><i class="fa fa-exclamation-triangle"></i></div>
      <div class="alert-text">
        Para registrar incidentes ingrese a la ficha del local asociado. En esta sección sólo se listan los incidentes registrados.
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label"><i class="fas fa-filter"></i> Filtros</h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="font-weight-bold">Código de Local</label>
                  <b-form-input id="input-1" v-model="filter.codigo" required placeholder="Código"></b-form-input>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Local de votación</label>
                  <b-form-input id="input-2" v-model="filter.descripcion" required placeholder="Nombre o dirección"></b-form-input>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Región</label>
                  <select class="form-control" v-model="filter.regionId">
                    <option :value="null">- Seleccionar -</option>
                    <option v-for="region in regiones" :value="region.regionId" :key="region.regionId">{{ region.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-4">
                  <label class="font-weight-bold"> Sub región</label>
                  <select class="form-control" v-model="filter.subRegionId">
                    <option :value="null">- Seleccionar -</option>
                    <option v-for="subRegion in subRegiones" :value="subRegion.subRegionId" :key="subRegion.subRegionId">{{ subRegion.nombre }}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold"> Orden</label>
                  <select class="form-control" v-model="filter.orden">
                    <option value="">- Seleccionar -</option>
                    <option value="DSC">Mas Reciente</option>
                    <option value="ASC">Mas Antiguo</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a href="#" class="btn btn-primary font-weight-bold mr-2 alignment-right" @click.prevent="fetchIncidentes()"><i class="fa fa-search"></i> Buscar</a>
                <a href="#" class="btn btn-success font-weight-bold mr-2 alignment-right" @click.prevent="downloadReporteIncidenteOtros()"><i class="la la-file-excel"></i> Reporte</a>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label"><i class="fa fa-fw fa-bell"></i> Incidentes</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center table-head-bg table-hover">
                <thead>
                  <tr>
                    <th>Fecha y hora</th>
                    <th>
                      Local / Dirección
                    </th>
                    <th>
                      Sub Región / Región
                    </th>
                    <th>
                      Incidente
                    </th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="incidente in incidentes" :key="incidente.localVotacionFichaIncidenteId">
                    <td>
                      {{ incidente.horaEventoActo | time }}<br /><small class="text-muted">{{ incidente.horaEventoActo | date }}</small>
                    </td>
                    <td>
                      {{ incidente.nombreLocalVotacion }}<br />
                      <small class="text-muted">{{ incidente.direccionLocalVotacion }}</small>
                    </td>
                    <td>
                      {{ incidente.nonbreSubRegion }}<br />
                      <small class="text-muted">{{ incidente.nombreRegion }}</small>
                    </td>
                    <td>
                      {{ incidente.tipoIncidenteParamDescripcion }}
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item href="#" @click.prevent="doMdlIncidenteOtros(incidente)"
                          ><span class="navi-icon"><i class="la la-info-circle text-dark"></i> Detalle</span></b-dropdown-item
                        >
                        <b-dropdown-item href="#" @click.prevent="doMdlAddEditIncidenteOtros(incidente.localVotacionFichaIncidenteId)"
                          ><span class="navi-icon"><i class="fa fa-pencil-alt text-dark"></i> Editar</span></b-dropdown-item
                        >
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="download-modal" size="md" title="Descargar Reporte" hide-footer>
        <div v-if="download.hasDownloadResult">
          <div class="mb-3">
            <p>
              Utilice el siguiente enlace para descargar o compartir el reporte:
            </p>
            <div class="card p-2 mb-2 border bg-light">
              {{ filesUrl + download.reportUrl }}
            </div>
            <a class="btn btn-default" :href="`${filesUrl + download.reportUrl}`" target="_blank"><i class="fa fa-download fa-fw"></i> Descargar reporte</a>
          </div>
        </div>
        <!--<div slot="modal-footer" class="d-flex justify-content-between w-100">
        <div class="float-left"></div>
        <b-button variant="primary" class="float-right" @click="$bvModal.hide('preview-modal')">
          Cerrar
        </b-button>
      </div>-->
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.cell-width-10 {
  width: 10%;
}

.cell-width-60 {
  width: 60%;
}

.cell-width-20 {
  width: 20%;
}

.alignment-right {
  float: right;
}

.padding-t-b {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import mdlIncidenteOtros from "@/components/incident/modal-incidente-otros";
import mdlAddEditIncidenteOtro from "@/components/incident/add-edit-incidente-otros";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import { INACTIVE_USER_TIME_THRESHOLD, USER_ACTIVITY_THROTTLER_TIME } from "@/helpers/constants.js";

export default {
  name: "list-incidente-otros",
  components: {
    mdlIncidenteOtros,
    mdlAddEditIncidenteOtro,
  },
  props: {},
  data: () => ({
    incidentes: [],
    edit: {
      mostrarModal: false,
      incidente: {},
    },
    pagination: {
      pageSize: 10,
    },
    filter: {
      regionId: null,
      subRegionId: null,
      codigo: "",
      descripcion: "",
      orden: "",
    },
    regiones: [],
    subRegiones: [],
    download: {
      hasDownloadResult: false,
      reportUrl: null,
    },
    editIncidente: {
      mostrarModal: false,
      incidenteOtroId: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doMdlIncidenteOtros: function(incidente) {
      this.edit.mostrarModal = true;
      this.edit.incidente = incidente;
    },
    doMdlAddEditIncidenteOtros: function(incidenteOtroId) {
      this.editIncidente.mostrarModal = true;
      this.editIncidente.incidenteOtroId = incidenteOtroId;
    },
    handleMdlIncidenteOtrosSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchIncidentes();
    },
    fetchIncidentes: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
      };

      //alert(JSON.stringify(model));

      this.$http.post(this.apiRoute("Incident", "ListLocalVotacionIncidenteOtros"), model).then(function(response) {
        //this.incidentes = response.body.data.items;
        var incidentesTemp = response.body.data.items;
        var vm = this;
        if (incidentesTemp != null && incidentesTemp.length > 0) {
          for (var a = 0; a < incidentesTemp.length; a++) {
            incidentesTemp[a].coolboxIndex = null;
            if (incidentesTemp[a].imagenes != null && incidentesTemp[a].imagenes.length > 0) {
              incidentesTemp[a].coolboxImages = incidentesTemp[a].imagenes.map(function(x) {
                return {
                  description: incidentesTemp[a].descripcionActo,
                  thumb: vm.apiResource(x.rutaArchivoThumb),
                  src: vm.apiResource(x.rutaArchivo),
                };
              });
              incidentesTemp[a].symbolImages = incidentesTemp[a].imagenes.slice(0, 3);
              incidentesTemp[a].masImagenes = incidentesTemp[a].imagenes.length - incidentesTemp[a].symbolImages.length;
            } else {
              incidentesTemp[a].coolboxImages = [];
              incidentesTemp[a].symbolImages = 0;
              incidentesTemp[a].masImagenes = 0;
            }
          }
        }

        this.incidentes = incidentesTemp;
        this.pagination = response.body.data.pagination;
      });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http.post(this.apiRoute("Region", "ListRegiones"), modelRegion).then(function(response) {
        this.regiones = response.body.data.items;
      });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    downloadReporteIncidenteOtros: function() {
      var model = {
        ...this.filter,
      };

      this.showLoading();
      this.download.hasDownloadResult = false;

      this.$http.post(this.apiRoute("Incident", "GetReporteIncidenteOtros"), model).then(function(response) {
        this.download.hasDownloadResult = true;
        this.download.reportUrl = response.body.data.reportUrl;
        this.$bvModal.show("download-modal");
        this.showLoading(false);
      });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
    "filter.orden": function(newValue, oldValue) {
      this.fetchIncidentes(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Incidentes" }]);
    this.fetchRegiones();
    this.fetchIncidentes();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
